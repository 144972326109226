:root {
    --go5-text-color-1: #ffffff;
    --go5-text-color-2: #f6f6f8;
    --go5-text-color-3: #ececf1;
    --go5-text-color-4: #dfdfe8;
    --go5-text-color-5: #d2d2da;
    --go5-text-color-6: #bcbcc8;
    --go5-text-color-7: #a5a5b6;
    --go5-text-color-8: #838395;
    --go5-text-color-9: #6a6a7c;
    --go5-text-color-10: #525260;
    --go5-text-color-11: #383842;
    --go5-text-color-12: #1c1c22;
}
.default {
    --bg-color: #fff;
    --error-text-color: #de3a45;
    --error-text-extra-color: #de3a4580;
    --error-border-text-color: #de3a45;
}
