@use "./Font";

.test-header-container {
    position: sticky;
    top: 0;
    width: 100%;
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.08);
    background-color: #fff;
    .test-header-wrapper {
        display: flex;
        justify-content: space-between;
        gap: 8px;
        padding: 8px 24px;
        flex-wrap: wrap;
        .test-header-detail {
            @include Font.go5-text-header-4();
            color: var(--go5-text-color-12);
            display: flex;
            align-items: center;
            gap: 16px;
            flex-wrap: wrap;
            img {
                height: 40px;
                object-fit: cover;
            }
        }
        .test-header-time-interval {
            display: flex;
            height: fit-content;
            align-items: center;
            justify-content: center;
            margin: auto 0 auto auto;
            padding: 6px 12px;
            border-radius: 6px;
            @include Font.go5-text-header-5();
            color: var(--go5-text-color-12);
            background-color: var(--go5-text-color-2);
            gap: 8px;
            .gf-icon-timer {
                font-size: 24px;
                &::before {
                    color: var(--go5-text-color-10);
                }
            }
        }
    }
}

.progress-bar-container {
    width: 100%;
    background-color: #fff;
    border-radius: 4px;
    overflow: hidden;
}

.progress-bar {
    height: 2px;
    background-color: #00cc99;
    transition: width 1s linear;
}
