$font-weight-bold: "gofive-bold", "myanmar-sans-pro-regular";
$font-weight-semi-bold: "gofive-semi-bold", "myanmar-sans-pro-regular";
$font-weight-medium: "gofive-medium", "myanmar-sans-pro-regular";
$font-weight-text: "gofive-regular", "myanmar-sans-pro-regular";

@mixin go5-text-header-1 {
    font-family: $font-weight-semi-bold !important;
    font-size: 32px !important;
    line-height: 44px !important;
}

@mixin go5-text-header-2 {
    font-family: $font-weight-semi-bold !important;
    font-size: 26px !important;
    line-height: 36px !important;
}

@mixin go5-text-header-3 {
    font-family: $font-weight-semi-bold !important;
    font-size: 24px !important;
    line-height: 32px !important;
}

@mixin go5-text-header-4 {
    font-family: $font-weight-semi-bold !important;
    font-size: 20px !important;
    line-height: 28px !important;
}

@mixin go5-text-header-5 {
    font-family: $font-weight-semi-bold !important;
    font-size: 18px !important;
    line-height: 26px !important;
}

@mixin go5-text-title-bold {
    font-family: $font-weight-bold !important;
    font-size: 16px !important;
    line-height: 24px !important;
}

@mixin go5-text-title-strong {
    font-family: $font-weight-semi-bold !important;
    font-size: 16px !important;
    line-height: 24px !important;
}

@mixin go5-text-title {
    font-family: $font-weight-medium !important;
    font-size: 16px !important;
    line-height: 24px !important;
}

@mixin go5-text-body-bold {
    font-family: $font-weight-bold !important;
    font-size: 14px !important;
    line-height: 20px !important;
}

@mixin go5-text-body-strong {
    font-family: $font-weight-semi-bold !important;
    font-size: 14px !important;
    line-height: 20px !important;
}

@mixin go5-text-body {
    font-family: $font-weight-medium !important;
    font-size: 14px !important;
    line-height: 20px !important;
}

@mixin go5-text-text {
    font-family: $font-weight-text !important;
    font-size: 14px !important;
    line-height: 20px !important;
}

@mixin go5-text-small-strong {
    font-family: $font-weight-semi-bold !important;
    font-size: 12px !important;
    line-height: 18px !important;
}

@mixin go5-text-small {
    font-family: $font-weight-text !important;
    font-size: 12px !important;
    line-height: 18px !important;
}

@mixin go5-text-extra-small {
    font-family: $font-weight-text !important;
    font-size: 10px !important;
    line-height: 14px !important;
}

@mixin go5-text-ellipsis {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 100%;
}
