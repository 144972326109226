@use "./Font";

.test {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    max-width: 60rem;
    padding: 16px;
    width: calc(100% - 32px);
}
.timer {
    font-size: 21pt;
    margin: 5px;
    color: var(--hl-color);
}
.box {
    @include Font.go5-text-header-2();
    overflow: hidden;
    height: 188px;
    display: flex;
    flex-wrap: wrap;
    gap: 2px;
    align-items: center;
    user-select: none;
    font-variant: no-common-ligatures;
    .word {
        position: relative;
        margin: 0 5px 0px;
        display: flex;
        flex-wrap: wrap;
        .vowel {
            position: absolute;
            visibility: hidden;
        }
        :not(.right):not(.wrong) {
            color: var(--go5-text-color-8);
        }

        .right {
            color: var(--go5-text-color-11);
        }

        .wrong {
            &:not(.extra) {
                color: var(--error-text-color);
            }

            &.extra {
                color: var(--error-text-extra-color);
            }
        }

        &.wrong {
            span {
                position: relative;
                &::before {
                    content: " ";
                    position: absolute;
                    height: 2px;
                    width: 100%;
                    background-color: var(--error-border-text-color);
                    bottom: 0;
                }
            }

            animation: shake 0.1s ease;
            @keyframes shake {
                0% {
                    transform: translateX(5px);
                }
                50% {
                    transform: translateX(-5px);
                }
                100% {
                    transform: translateX(0);
                }
            }
        }
    }
    .typedWord {
        position: absolute;
        top: 0;
        left: 0;
    }

    #active {
        position: relative;
        width: max-content;
    }

    #caret {
        transition: left 0.1s linear;
        position: absolute;
        width: 2px;
        height: 38px;
        background-color: #000;
        margin-top: -1px;
        &.blink {
            animation: blink 1.5s infinite 1s;
        }
    }
    @keyframes blink {
        0%,
        100% {
            opacity: 1;
        }
        50% {
            opacity: 0;
        }
    }
}

.wpm {
    @include Font.go5-text-title-strong;
    color: var(--go5-text-color-11);
    background-color: var(--go5-text-color-2);
    padding: 6px 16px;
    width: fit-content;
    border-radius: 6px;
    margin-bottom: 24px;
    align-self: flex-end;
}
